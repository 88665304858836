/* src/components/HealthTopics.css */

.health-topics {
    padding: 2rem 3rem;
    background-color: #f9f9f9;
    color: #333;
  }
  
  .health-topics h1 {
    color: #FF7F50; /* Coral */
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .topics-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .topic {
    background-color: #FFDAB9; /* Peach */
    padding: 1.5rem;
    width: 304px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .topic h3 {
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .topic p {
    color: #333;
  }

  .page-image {
    margin: auto;
    display: flex;
    width: 248px;
    border-radius: 4%;
  }

  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #FF6347;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.btn:hover {
  background-color: #c24b35;
}

  
  @media (min-width: 768px) {
    .topics-list {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 4rem;
    }
  
    .topic {
      flex: 1 1 calc(30% - 2rem);
      box-sizing: border-box;
      width: 280px;
      height: 504px;
    }

    .welcome-text {
      margin: auto 2rem;
    }
  }
  