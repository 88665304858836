/* src/App.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #FFF;
}

.App {
  text-align: center;
  color: #333;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 4rem;
}

.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.main-image {
  width: 284px;
  height: 224px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #FFC0CB;
}

.welcome-text {
  text-align: center;
}

h1 {
  color: #c24b35;
}

p {
  color: #333;
}

footer {
  background-color: #98FF98;
  padding: 1rem;
  text-align: center;
  color: #333;
}

.main-content {
  padding: 2rem 1rem;
}

.content-section {
  padding: 3rem 1rem;
  background-color: #FFF;
  text-align: left;
  margin: 0 2rem;
}

.content-section h2 {
  color: #FF7F50;
  margin-bottom: 1rem;
}

.content-section p {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.feature {
  background-color: #FFDAB9;
  padding: 1.5rem;
  border-radius: 8px;
}

.feature h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.feature p {
  color: #333;
}

.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #FF7F50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
}

.cta-button:hover {
  background-color: #E57373;
}

/* App.css */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
}

.recent-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.new-post-form {
  margin: 20px;
  display: flex;
  flex-direction: column;
  width: 300px;
}

.new-post-form input,
.new-post-form textarea {
  margin: 5px 0;
  padding: 10px;
  font-size: 16px;
}

.new-post-form button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

@media (min-width: 768px) {

  .hero-section {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .main-content {
    margin: 1rem 6rem 0 6rem;
  }

  .main-image {
    width: 416px;
    height: 320px;
  }

  .content-section {
    margin: 0 8rem;
  }

  .features {
      flex-direction: row;
      justify-content: space-between;
  }
}
