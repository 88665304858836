footer {
    background-color: #98FF98;
    padding: 1rem;
    text-align: center;
    color: #333;
}

.footer-items {
    font-size: 12px;
}

.disclaimerp {
    line-height: 16px;
    text-align: left;
    margin: 1rem;
}

@media (min-width: 768px) {


.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-items {
    width: 33%;
    font-size: 13px;
    margin: auto 1rem;
}

}
