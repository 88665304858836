.article-container {
    margin: 2rem;
    text-align: left;
}

h1 {
    margin: 2rem auto 3rem auto;
    font-size: 32px;
    text-align: center;
}

h2 {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

h4 {
    margin-top: 4rem;
}

.reference-h3 {
    margin-top: 4rem;
}

p {
    line-height: 24px;
    letter-spacing: .3px;
    margin-bottom: 2rem;
}

ul {
    margin-bottom: 2rem;
}

li {
    margin-bottom: 1rem;
}

.image {
    width: 320px;
    margin: -1rem auto;
}

.image1 {
    width: 400px;
}

.image2 {
    width: 304px;
}

@media (min-width: 768px) {
    .article-container {
        margin: 2rem 4rem;
    }

    .image {
        width: 520px;
        margin: -2rem auto;
    }

    .image1 {
        width: 600px;
    }

    .image2 {
        width: 504px;
    }

}

@media (min-width: 1024px) {
    .article-container {
        margin: 2rem 6rem;
    }

    h1 {
        margin: auto 4rem 6rem 4rem;
        line-height: 48px;
    }

    .image {
        width: 600px;
    }

    .image1 {
        width: 800px;
        margin-bottom: 2rem;
    }

    .image2 {
        width: 600px;
        height: 344px;
    }


}