/* src/components/Navbar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #FF7F50;
    color: #fff;
}

.logo-image {
    width: 64px;
    margin: auto 2rem;
}

.navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
}

.navbar-item {
    padding: 0.5rem 1rem;
    color: #fff;
    text-decoration: none;
}

.navbar-item a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    display: inline-block;
}

.navbar-item a:hover, .navbar-item a.active {
    background-color: #FF6347;
    border-radius: 4px;
}

.navbar-burger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: auto 2rem;
}

.navbar-burger span {
    width: 25px;
    height: 3px;
    background-color: #fff;
}

@media (min-width: 768px) {
    
    .navbar-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .logo-image {
        width: 80px;
        margin: auto 4rem;
    }

    .navbar-burger {
        display: none;
    }
}

.is-active {
    display: flex;
}